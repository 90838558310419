export interface Position {
    x: number;
    y: number;
}

export function positionEquals(a: Position, b: Position): boolean {
    return a.x === b.x && a.y === b.y;
}

export const ORIGO: Position = {
    x: 0,
    y: 0
};

export function getPathMiddlePoint(path: Position[]): Position {
    const segmentLengths = [];
    for (let i = 0; i < path.length - 1; ++i) {
        const a = path[i];
        const b = path[i + 1];
        segmentLengths.push(Math.hypot(b.x - a.x, b.y - a.y));
    }
    const totalLength = segmentLengths.reduce((a, b) => a + b, 0);
    const halfLength = 0.5 * totalLength;
    let beginLength = 0;
    for (let i = 0; i < path.length - 1; ++i) {
        const segmentLength = segmentLengths[i];
        const endLength = beginLength + segmentLength;
        if (halfLength <= endLength) {
            const a = path[i];
            const b = path[i + 1];
            const t = (halfLength - beginLength) / segmentLength;
            return {
                x: a.x + t * (b.x-a.x),
                y: a.y + t * (b.y-a.y)
            };
        }
        beginLength = endLength;
    }
    return path[Math.floor(path.length / 2)];
}

import Konva from "konva";
import { Position } from "./Position";

export class Extent {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;

    constructor(minX: number, minY: number, maxX: number, maxY: number) {
        this.minX = minX;
        this.minY = minY;
        this.maxX = maxX;
        this.maxY = maxY;
    }

    static empty(): Extent {
        return new Extent(Infinity, Infinity, -Infinity, -Infinity);
    }

    clone(): Extent {
        return new Extent(this.minX, this.minY, this.maxX, this.maxY);
    }

    area(): number {
        return (this.maxX - this.minX) * (this.maxY - this.minY);
    }

    isInside(position: Position): boolean {
        const { minX, minY, maxX, maxY } = this;
        const { x, y } = position;
        return x >= minX && x <= maxX && y >= minY && y <= maxY;
    }

    doesInclude(position: Extent): boolean {
        return this.minX <= position.minX && this.maxX >= position.maxX
        && this.minY <= position.minY && this.maxY >= position.maxY;
    }

    center(): Position {
        const { minX, minY, maxX, maxY } = this;
        return {
            x: 0.5 * (minX + maxX),
            y: 0.5 * (minY + maxY)
        };
    }

    doesIntersect(other: Extent): boolean {
        return doIntervalsIntersect(this.minX, this.maxX, other.minX, other.maxX)
            && doIntervalsIntersect(this.minY, this.maxY, other.minY, other.maxY);
    }

    expandToIncludePoint(x: number, y: number): void {
        if (x < this.minX)
            this.minX = x;
        if (y < this.minY)
            this.minY = y;
        if (x > this.maxX)
            this.maxX = x;
        if (y > this.maxY)
            this.maxY = y;
    }

    expandToIncludeEllipse(x: number, y: number, rx: number, ry: number): void {
        if (x - rx < this.minX)
            this.minX = x - rx;
        if (y - ry < this.minY)
            this.minY = y - ry;
        if (x + rx > this.maxX)
            this.maxX = x + rx;
        if (y + ry > this.maxY)
            this.maxY = y + ry;
    }

    expandToIncludeExtent(extent: Extent): void {
        if (extent.minX < this.minX)
            this.minX = extent.minX;
        if (extent.minY < this.minY)
            this.minY = extent.minY;
        if (extent.maxX > this.maxX)
            this.maxX = extent.maxX;
        if (extent.maxY > this.maxY)
            this.maxY = extent.maxY;
    }

    static combine(extents: (Extent | undefined)[]): Extent | undefined {
        const bb = Extent.empty();
        for (const extent of extents)
            if (extent !== undefined)
                bb.expandToIncludeExtent(extent);
        if (isFinite(bb.minX))
            return bb;
        else
            return undefined;
    }

    flipY(): Extent {
        return new Extent(this.minX, -this.maxY, this.maxX, -this.minY);
    }

    transform(tr: Konva.Transform) {
        const points = [];
        points.push(tr.point({x: this.minX, y: this.minY}));
        points.push(tr.point({x: this.minX, y: this.maxY}));
        points.push(tr.point({x: this.maxX, y: this.minY}));
        points.push(tr.point({x: this.maxX, y: this.maxY}));
        this.minX = Math.min(...points.map((o) => o.x));
        this.minY = Math.min(...points.map((o) => o.y));
        this.maxX = Math.max(...points.map((o) => o.x));
        this.maxY = Math.max(...points.map((o) => o.y));
    }
}

function doIntervalsIntersect(minA: number, maxA: number, minB: number, maxB: number): boolean {
    return Math.max(minA, minB) <= Math.min(maxA, maxB);
}
